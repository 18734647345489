import { container } from 'tsyringe'
import Vue from 'vue'
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import store from '@/app/ui/store'
import { ExportDataPresenter } from '@/app/ui/presenters/ExportDataPresenter'
import { EventBus, EventBusConstants, Utils } from '@/app/infrastructures/misc'

export interface ExportDataState {
  isLoading: boolean
}

@Module({ namespaced: true, store, name: 'export-data', dynamic: true })
class ExportDataController extends VuexModule implements ExportDataState {
  private presenter: ExportDataPresenter = container.resolve(
    ExportDataPresenter
  )
  public isLoading = false

  @Action({ rawError: true })
  public getExportedFile(params: Record<string, any>) {
    this.setLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .export(formattedParams)
      .then(res => {
        EventBus.$emit(EventBusConstants.DOWNLOAD_EXPORT_DATA_READY, {
          downloadUrl: res,
        })
      })
      .catch(error => {
        Vue.notify({
          title: 'Download Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Mutation
  public setLoading(bool: boolean) {
    this.isLoading = bool
  }
}

export default getModule(ExportDataController)
