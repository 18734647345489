




























































import { Component, Vue, Watch } from 'vue-property-decorator'
import controller from '@/app/ui/controllers/ExportDataController'
import Button from '@/app/ui/components/Button/index.vue'
import DateTimePicker from '@/app/ui/components/DateTimePicker/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import Loading from '@/app/ui/components/Loading/index.vue'
import { EventBus, EventBusConstants, Utils } from '@/app/infrastructures/misc'
import dayjs from 'dayjs'
import dayjsUTC from 'dayjs/plugin/utc'

dayjs.extend(dayjsUTC)

@Component({
  components: {
    Button,
    TextInput,
    DropdownSelect,
    Loading,
    DateTimePicker,
  },
})
export default class ExportDataPage extends Vue {
  controller = controller
  isGuest = Utils.isGuest()
  filterOptions = [
    { label: 'Fix Revenue', value: 'FixRevenue' },
    { label: 'Potential Revenue', value: 'PotentialRevenue' },
    { label: 'Lost Revenue', value: 'LostRevenue' },
  ]
  parameters = {
    dateFrom: '',
    dateTo: '',
    reportType: this.filterOptions[0],
  }

  created() {
    if (this.$route.query instanceof Object) {
      const queries = this.$route.query as Record<string, never>
      this.parameters = {
        reportType:
          this.filterOptions.find(
            (item) => item.value === queries.reportType
          ) || this.filterOptions[0],
        dateFrom: queries.dateFrom,
        dateTo: queries.dateTo,
      }
    }

    EventBus.$on(
      EventBusConstants.DOWNLOAD_EXPORT_DATA_READY,
      (instance: { downloadUrl: string }) => {
        const link = document.createElement('a')
        link.href = instance.downloadUrl
        link.setAttribute('download', 'file.xlsx') //or any other extension
        document.body.appendChild(link)
        link.click()
        link.remove()
      }
    )
  }

  @Watch('parameters', { deep: true })
  onParametersChanged(val: Record<string, never>) {
    if (
      dayjs(val.dateTo, 'YYYY-MM-DD').isBefore(
        dayjs(val.dateFrom, 'YYYY-MM-DD')
      ) ||
      !val.dateTo
    ) {
      this.parameters.dateTo = val.dateFrom
    }
  }

  @Watch('params')
  onParamsChanged(val: Record<string, never>) {
    this.$router.replace({
      query: {
        ...val,
        dateFrom: this.parameters.dateFrom
          ? dayjs(this.parameters.dateFrom).format('YYYY-MM-DD')
          : null,
        dateTo: this.parameters.dateTo
          ? dayjs(this.parameters.dateTo).format('YYYY-MM-DD')
          : null,
      },
    })
  }

  get params() {
    return {
      ...this.parameters,
      dateFrom: this.parameters.dateFrom
        ? dayjs(this.parameters.dateFrom, 'YYYY-MM-DD').utc().toISOString()
        : null,
      dateTo: this.parameters.dateTo
        ? dayjs(this.parameters.dateTo, 'YYYY-MM-DD')
            .endOf('days')
            .utc()
            .toISOString()
        : null,
      reportType: this.parameters.reportType.value,
    }
  }

  private getExportUrl() {
    controller.getExportedFile(this.params)
  }

  private formatDate(date: string) {
    return dayjs(date, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY HH:mm Z')
  }

  beforeDestroy() {
    EventBus.$off(EventBusConstants.DOWNLOAD_EXPORT_DATA_READY)
  }
}
